<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card v-if="editedItem">
        <v-card-title>
          <span class="text-h5">{{ $t('booking.calendar.editEvent', locale) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-switch
                  v-model="editedItem.IsCustomLimit"
                  :label="$t('booking.scheduleForm.customLimit', locale)"
                  hide-details
                  style="margin-top:0;"
                />

                <seats-limit
                  v-if="editedItem.IsCustomLimit"
                  :editedItem="editedItem"
                  :locale="locale"
                  :onChange="handleChangeSeatsLimit"
                />
                <!--custom-text-field-number
                  v-model="editedItem.Seats"
                  :label="$t('booking.activities.seats', locale).concat(' (10)')"
                  style="margin-top:10px"
                />
                <small>Límite total de plazas de la actividad.</small>
                <custom-text-field-number
                  v-model="editedItem.SeatsByBooking"
                  :label="$t('booking.activities.seatsByBooking', locale).concat(' (5)')"
                  style="margin-top:10px"
                />
                <small>Límite de plazas por reserva.</small-->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            @click="handleClose"
          >
            
            {{ $t('common.cancel', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
//import CustomTextFieldNumber from '@/components/CustomTextFieldNumber'
import SeatsLimit from '@/components/seatsLimit/Index'
export default {
  components: {
    //CustomTextFieldNumber,
    SeatsLimit,
  },
  props: {
    eventID: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
  }),
  watch: {
    eventID () {
      this.prepareDialog()
    },
  },
  methods: {
    prepareDialog () {
      if (this.eventID === null) {
        this.dialog = false
        return
      }
      this.handleGetEvent ()
    },
    handleGetEvent () {
      this.editedItem = null
      api.getItem ('booking', `v1/private/events/`, this.eventID)
        .then(response => {
          console.log('jhmm', response)
          if (!response) return
          this.editedItem = {
              IsCustomLimit: response.IsCustomLimit ? true : false,
              SeatsType: response.SeatsType ? response.SeatsType : 'byCapacity',
              BookingLimit: response.BookingLimit,
              Seats: response.Seats,
              SeatsByBooking: response.SeatsByBooking,
              ResourceID: response.ResourceID,
            }
          this.dialog = true
        })
    },
    handleChangeSeatsLimit (v) {
      this.editedItem.SeatsType = v.SeatsType
      this.editedItem.Seats = v.Seats
      this.editedItem.SeatsByBooking = v.SeatsByBooking
      this.editedItem.BookingLimit = v.BookingLimit
      this.editedItem.ResourceID = v.ResourceID
    },
    handleSave () {
      api.updateItem ('booking', `v1/private/events/`, this.eventID.concat('/calendar'), this.editedItem)
        .then(response => {
          if (response.data.status === 'OK') this.handleClose()
          else alert('Error')
        })
        .catch (() => {
          alert( 'Error' )
        })
    },
    handleClose () {
      this.editedItem = null
      this.onClose(null)
    },
  },
}
</script>

