<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card style="padding-top: 20px" v-if="event">
        <v-card-text style="padding:0" v-if="actionRequired">
          <v-container style="padding: 0 20px">
            <h3>El evento tiene reservas. ¿Qué desea hacer?</h3>
            <center style="margin-top: 20px">
              <v-btn-toggle
                v-model="action"
                color="primary"
                group
              >
                <v-btn value="delete">
                  Eliminar
                </v-btn>

                <v-btn value="relocate">
                  Recolocar
                </v-btn>
              </v-btn-toggle>
            </center>   
            <center>
              <small style="color: red;" v-if="action==='delete'">Las reservas serán eliminadas por completo.</small>
              <small style="color: gray;" v-if="action==='relocate'">Las reservas serán marcadas como pendiente de rehubicar.</small>
            </center>
            <v-checkbox
              v-if="allowNotification && action === 'delete'"
              v-model="sendOwnerMail"
              :label="$t('booking.notifications.sendNotificationOwnerMail', locale)"
              hide-details
            />
          </v-container>
        </v-card-text>
        <v-card-text style="padding:0" v-else>
          <v-container>
            {{ $t('common.messages.confirmDelete', locale) }}
          </v-container>
        </v-card-text>
        <v-card-actions style="padding: 0 20px 20px 0">
          <v-spacer/>
          <v-btn
            color="default"
            text
            @click="() => { onClose() }"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            :elevation="0"
            color="red"
            dark
            @click="handleDelete"
          >
            {{ $t('common.delete', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import utilsBooking from '@/services/bookings'
export default {
  components: {
  },
  props: {
    eventID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: null,
    },
    workspaceID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    event: null,
    editedItem: null,
    showDialog: false,
    loading: false,
    minDate: utils.checkDate(new Date()),
    actionRequired: false,
    action: null,
    allowNotification: false,
    sendOwnerMail: false,
  }),
  watch: {
    dialog () {
      this.getData()
      this.showDialog = this.dialog
    },
  },
  mounted () {
    this.getData()
    this.showDialog = this.dialog
  },
  methods: {
    getData () {
      if (!this.eventID) return
      api.getItem('booking', `v1/private/events/`, this.eventID)
        .then(response => {
          this.event = response
          this.action = null

          this.actionRequired = this.event.Book > 0 || this.event.Cancel > 0 ? true : false
          if (this.actionRequired) {
            this.action = 'delete'
            this.handleGetWorkspaceConfig()
          }
        })
    },
    handleGetWorkspaceConfig () {
      api.getItem('booking', `v1/private/workspaces/`, this.workspaceID)
        .then(response => {
          this.allowNotification = response.SendOwnerMail === 1
        })
    },
    handleDelete () {
      const body = this.actionRequired ? {
        action: this.action,
        sendOwnerMail: this.sendOwnerMail
      } : null

      api.deleteItem('booking', `v1/private/events/`, this.eventID, body)
        .then(response => {
          if (response) {
            this.onClose(true)
            if(this.action === 'relocate') utilsBooking.handleCheckRelocateReservations('booking', this.workspaceID)
          }
        })
    },
  },
}
</script>

